<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item>
				<el-input v-model="name" placeholder="药品名称" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search()">查询</el-button>
				<el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="name" header-align="center" align="center" label="名称"></el-table-column>
			<el-table-column prop="serialNumber" header-align="center" align="center" label="编号"></el-table-column>
			<el-table-column prop="price" header-align="center" align="center" label="价格">
				<template slot-scope="scope">{{scope.row.price }}</template>
			</el-table-column>
			<el-table-column prop="SpecificationUnit" header-align="center" align="center" label="规格单位"></el-table-column>
			<el-table-column prop="SpecificationFactor" header-align="center" align="center" label="规格系数"></el-table-column>
			<el-table-column prop="abbr" header-align="center" align="center" label="拼音首字母"></el-table-column>
			<el-table-column header-align="center" align="center" label="是否上架">
				<template slot-scope="scope">
					<el-switch
						v-model="scope.row.grounding"
						@change="changeGroundingStatus(scope.row.id, scope.row.grounding)"
						active-color="#13ce66"
						inactive-color="#ff4949"
						:active-value="1"
						:inactive-value="2"
						:active-text="scope.row.grounding === 1 ? '已上架' : '未上架'"
					>
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column header-align="center" align="center" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id, '', $route.meta.navIndex)">查看详情</el-button>
					<el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id, 'edit', $route.meta.navIndex)">修改</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>
<script>
const kl = /* process.env.VUE_APP_ENV === 'development' ? '1080' :  */'1080';
const yp = /* process.env.VUE_APP_ENV === 'development' ? '1315' :  */'1315';
export default {
	data () {
		return {
			name: '',
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			pharmacyNumber: '8'
		};
	},
	created () {
		if (this.$route.name === 'KlMedicine') {
			this.pharmacyNumber = kl;
		} else {
			this.pharmacyNumber = yp;
		}
		if (this.$route.query.pageSize && this.$route.query.currentPage) {
			this.search(+this.$route.query.pageSize, +this.$route.query.currentPage);
		} else {
			this.search();
		}
	},
	methods: {
		search (pageSize = 10, currentPage = 1) {
			debugger;
			this.pageSize = pageSize;
			this.currentPage = currentPage;
			this.total = 0;
			this.getData();
		},
		getData () {
			this.$root.request('medicineList', {
				pharmacyNumber: this.pharmacyNumber,
				name: this.name,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows;
				}
			});
		},
		addOrUpdateHandle (id, type, navIndex) {
			this.$root.go('/mnt/addOrUpdateMedicine', {
				id,
				type,
				navIndex,
				pageSize: this.pageSize,
				currentPage: this.currentPage,
				pharmacyNumber: this.pharmacyNumber
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		changeGroundingStatus (id, status) {
			this.$root.request('changeGroundingStatus', {
				id,
				status
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
				} else {
					this.$root.elMsg('操作失败', 'error');
				}
			});
		}
	},
	watch: {
		'$route.name': {
			handler (val) {
				if (this.$route.name === 'KlMedicine') {
					this.pharmacyNumber = kl;
				} else {
					this.pharmacyNumber = yp;
				}
				this.getData();
			},
			deep: true
		}
	}
};
</script>